.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Press Start 2P', cursive;
}

h1 {
  font-size: 2em; /* Adjust the size as needed */
  text-shadow: 0px 0px 5px rgba(0, 255, 0, 0.7); /* Gives a slight 3D effect */
  margin-bottom: 0.5em; /* Adjust the spacing between the title and the GIF */
}

h2 {
  font-family: 'Press Start 2P', cursive;
  font-size: 1em; /* Smaller font size for the subtitle */
  color: #ffffff; /* Or any color you prefer */
  text-shadow: 0px 0px 5px rgba(0, 255, 0, 0.7); /* Optional: Adds a glow to the text */
  margin: 0; /* Adjust as needed */
  padding: 10px 20px; /* Adjust as needed */
}

h3 {
  font-family: 'Press Start 2P', cursive;
  font-size: .75em; /* Smaller font size for the subtitle */
  color: #ffffff; /* Or any color you prefer */
  text-shadow: 0px 0px 5px rgba(0, 255, 0, 0.7); /* Optional: Adds a glow to the text */
  margin: 0; /* Adjust as needed */
  padding: 10px 20px; /* Adjust as needed */
}

.countdown {
  font-size: 1.5em;
  margin: 20px 0; /* This adds margin above and below the countdown timer */
}

.btn {
  font-family: 'Press Start 2P', cursive;
  font-size: 0.75em; /* Adjust as needed */
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  border: 2px solid #00ff00; /* Green border for the pixelated look */
  padding: 10px 20px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-shadow: 0px 0px 5px rgba(0, 255, 0, 0.7); /* Green text glow */
  box-shadow: 0px 0px 10px rgba(0, 255, 0, 0.5); /* Green box glow */
  image-rendering: pixelated; /* Gives the text a blocky, pixelated look */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.btn:hover {
  background-color: #005500; /* Darker green background on hover */
}
img {
  margin-bottom: 20px;
  margin-top: 20px; /* Adjust the value as needed */
}

/* or, alternatively */

.buttons {
  margin-top: 20px; /* Adjust the value as needed */
}

.myImage {
  width: 500px; /* Set this to your desired width */
  height: auto; /* This will maintain the aspect ratio */
}

.progress-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
}

.progress-step {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.step-circle {
  width: 40px;
  height: 40px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 10px 50px;
}

.step-title {
  font-size: 20px; /* Set the font size for the description text */
  margin-top: 5px; /* Adds space above the description text *//* Style for your title text */
}

.step-description {
  font-size: 14px; /* Set the font size for the description text */
  margin-top: 5px; /* Adds space above the description text *//* Style for your description text */
}

